<template>
  <page-view class="articles-detail" :up-class="'articles-detail'">
    <div class="pc_container">
      <div class="articles-title">
        <div class="left">
          <span @click="$router.push({ name: 'ShareGoods' })">闲物互联</span><i class="el-icon-arrow-right"></i>
          <span @click="backs"> {{ goodsDetails.shopCategoryName }}</span><i class="el-icon-arrow-right"></i>
          <span>{{ goodsDetails.name }}</span>
        </div>
        <div class="right">
          <!-- <div class="btn">进入店铺</div> -->
          <div class="right_good">信誉商家</div>
          <div @click="toMyShops" class="name">{{ comInfo?.name }}</div>
        </div>
      </div>

      <div class="content_container">
        <div class="goods_container">
          <div class="left">
            <div class="spec-preview">
              <img :src="goodsImgArr[currentGoodsIndex]" />
              <div class="event" @mousemove="moves"></div>
              <div class="big" v-if="!isMobile">
                <img :src="goodsImgArr[currentGoodsIndex]" ref="big" />
              </div>

              <div v-if="!isMobile" class="mask" ref="mask"></div>
            </div>

            <div style="overflow: hidden;">
              <div @click="changeImg(0)"><i class="el-icon-arrow-left"></i></div>
              <div :style="{transform: `translateX(${mmmove}%)`}">
                <img :src="goodsImg" :class="{ active: currentGoodsIndex === index }"
                  v-for="(goodsImg, index) in goodsImgArr" :key="index" @mouseenter="handleImg(index)" />
              </div>
              <div @click="changeImg(1)"><i class="el-icon-arrow-right"></i></div>
            </div>
          </div>

          <div class="middle">
            <div class="middle_top">
              <div class="middle_title">产品标题: {{ goodsDetails.name }} </div>
              <div class="middle_content">产品说明: {{ goodsDetails.description }}</div>
              <div class="middle_price">
                <div>本产品经过实拍，未检测到有同等网图</div>
                <div>
                  <span>
                    原 价:<span style="color: red; font-weight: 700;"> ￥<span class="bigred">{{ goodsDetails.marketPrice
                    }}</span></span>
                  </span>
                  <div>
                    <span>
                      促 销:
                      <span style="color: red; font-weight: 700;"> ￥<span class="bigred">{{
                        goodsDetails.price }}</span></span>
                      <span style="color: #5684AE;font-weight: 600;letter-spacing: 1px;font-size: 14px;">降价通知</span>
                    </span>
                    <span>发货地址 : &nbsp;&nbsp;<span style="color: #959595;">{{ goodsDetails.cityName }}
                        {{ goodsDetails.districtName }}</span></span>
                  </div>
                </div>
              </div>
              <div class="specification_list" v-if="allGoodsMarquee && allGoodsMarquee.length > 0">
                <div class="specification_item"
                  v-for="(item, sub) in allGoodsMarquee" :key="item.id">
                  <div class="title_container">
                    <span>{{ item.name }}</span>
                    <span>{{ currentSelect.split("/")[sub] }}</span>
                  </div>
                  <div class="options_container">
                    <div class="option"
                      :class="{ active: currentSelect.split('/').includes(option), noGoods: isNoGoods(item.id, option), }"
                      v-for="(option, index) in item.value" :key="index" @click="selectMarquee(item.id, option)">
                      {{ option }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="select_number">
                <el-input-number v-model="selectNumber" controls-position="right" @change="handleChange" :min="1"
                  :max="999"></el-input-number>
                <div class="btn" @click="toShop">抢购</div>
                <div class="btn" @click="toAddCar">加入购物车</div>
              </div>
              <div class="xins">
                <div @click="toCollect"><span id="star"></span>
                  <div><strong>收藏</strong></div>
                </div>
                <div>
                  <strong style="color: black;font-weight: bold;">信用评价 :
                    &nbsp;&nbsp;&nbsp;</strong>该用户已经完成实名认证，近期没有不良记录，已在平台成交过5单，均没产生任何纠纷
                  <span @click="toMyShops" style="cursor: pointer; color: #959BC7;">&nbsp;&nbsp;查看主页 ></span>
                </div>
              </div>
            </div>
          </div>

          <div class="right">
            <div>其他产品</div>
            <div class="right_item" v-for="item in tuijian" @click="toddd(item)" :key="item.id">
              <div><img :src="item.image" />
                <div style="background-color: #EDEDED;padding: 3% 0%;color: #434343;">{{ item.name }}</div>
              </div>
              <div><span>￥{{ item.price }}</span></div>
            </div>
            <div class="right_icon" v-if="tuijian && tuijian.length > 0">
              <i class="el-icon-arrow-left" @click="loadScroll(0)"></i>
              <i class="el-icon-arrow-right" @click="loadScroll(1)"></i>
            </div>
            <div v-if="tuijian && tuijian.length == 0" style="color: #5D5D5D;font-size: max(12px, 0.8vw);">该店铺尚无其他商品</div>
          </div>
        </div>

        <div class="recommend_goods">
          <div class="recommend_title"><span>平台推荐</span></div>
          <div class="carousel_container" style="display: flex">
            <div class="recommend_item" v-for="goods in recommendGoods" :key="goods.id" @click="toddd(goods)">
              <div><img :src="goods.image" />
                <div style="background-color: #EDEDED;padding: 3% 0%;color: #434343;">{{ goods.name }}</div>
              </div>
              <div><span>￥{{ goods.price }}</span></div>
            </div>
          </div>
        </div>

        <div class="words">
          <div class="words_title">
            <span :class="pingLunIndex == 8 ? 'wasyou' : ''" @click="changePingLunIndex(8)">产品详情</span>
            <span :class="pingLunIndex == 0 ? 'wasyou' : ''" @click="changePingLunIndex(0)">全部评价({{
              pingLunList.length }})</span>
            <span :class="pingLunIndex == 3 ? 'wasyou' : ''" @click="changePingLunIndex(3)">好评({{
              pingLun1List.length }})</span>
            <span :class="pingLunIndex == 1 ? 'wasyou' : ''" @click="changePingLunIndex(1)">差评({{
              pingLun2List.length }})</span>
          </div>
          <ul class="uls" v-if="pingLunIndex != 8">
            <li v-for="(item, index) in testList" :key="index">
              <div>评论内容&nbsp;&nbsp;:&nbsp;&nbsp;{{ item.goodsCommentContent }}</div>
              <div>产品描述&nbsp;&nbsp;:&nbsp;&nbsp;<el-rate style="display: inline-block;" disabled
                  :value="item.goodsCommentGrade1" :colors="colors"></el-rate></div>
            </li>
          </ul>
          <div v-else v-html="detailsImage" class="materials_html"></div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "ArticlesDetail",
  data() {
    return {
      isMeta: false,
      //公司信息
      comInfo: null,
      // 商品详情
      goodsDetails: {},
      // 推荐商品
      recommendGoods: [],
      // 商品图
      goodsImgArr: [],
      // 当前展示的商品图下标
      currentGoodsIndex: 0,
      // 接口获取的商品规格
      getGoodsMarqueeData: [],
      // 处理后的全部商品规格
      allGoodsMarquee: [],
      // 全部规格id
      allGoodsMarqueeId: [],
      // 可选规格
      availableArr: [],
      // 选择的规格
      currentSelect: "",
      //选择的数量
      selectNumber: 1,
      dizhis: [],//地址数据
      isLogin: false,//是否登录
      pingLunList: [],//全部
      pingLun1List: [],
      pingLun2List: [],
      testList: [],
      pingLunIndex: 8,
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      tuijian: [],//右侧商家推荐列表
      tuijianCurrent: 0,//右侧商家推荐列表当前页
      tuijianTotal: 0,//右侧商家推荐列表总条数
      isGoods: false,//是否来自闲物互联
    };
  },
  computed: {
    ...mapState(["screenWidth", "infoObj"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    // 防止商品详情图片溢出
    detailsImage() {
      const introduction = this.goodsDetails.introduction;
      let htmlSrt = "";
      if (introduction) {
        htmlSrt = introduction.replace(
          /\<img/g,
          '<img style="width:100%;height:auto;"'
        );
      }
      return htmlSrt;
    },
    // 当前选择的规格对象
    selectGoodsInfo() {
      const obj = this.getGoodsMarqueeData.find((item) => {
        return item.specificationNames === this.currentSelect;
      });
      return obj;
    },
    noMoreRight() {
      if (this.tuijianTotal <= 2 || Math.ceil(this.tuijianTotal / 2) == this.tuijianCurrent + 1) {
        return true
      }
    },
    mmmove(){
      if (this.goodsImgArr.length <= 5) {
        return 0
      }else{
        // (this.currentGoodsIndex - 5)
        // return 100
      }
    }
  },
  created() {
    this.setNavIndex("j");
    this.getGoodsDetails(this.$route.params.goodsId);
    this.getGoodsMarquee(this.$route.params.goodsId);
    if (this.$route.params.isGoodFrom && this.$route.params.isGoodFrom == 'true') {
      this.isGoods = this.$route.params.isGoodFrom
    }
    if (window.localStorage.getItem('token') && window.localStorage.getItem('token') != '') {
      this.isLogin = false
    } else {
      this.isLogin = true
    }
  },
  mounted() {
    this.askCollect();
  },
  watch: {
    screenWidth(newV, oldV) {
      if (newV < 768) {
        this.isMeta = true;
      } else {
        this.isMeta = false;
      }
    },
  },
  methods: {
    ...mapActions(["setNavIndex"]),
    backs() {
      // console.log(this.goodsDetails,'this.goodsDetails.shopCategoryId');

      this.$router.push({
        name: 'ShareGoodsAll',
        query: {
          id: this.goodsDetails.shopCategoryId,
          pid: 0,
        },
      });
    },
    //查看店铺
    toMyShops() {
      const openRoute = this.$router.resolve({
        name: "MyShops",
        query: {
          syscompanyid: this.comInfo.companyid,
          id: this.$route.params.goodsId
        },
      });
      window.open(openRoute.href, "_blank");
    },
    // 获取好物推荐
    async getGoodsList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      let result = await this.$api.getMaterList({
        categoryId: this.goodsDetails.shopCategoryId,
        goodsMainId: this.goodsDetails.id,
        index: 0,
        size: 5,
        type: 17,
      });
      loading.close();

      if (result.code === 200) {
        this.recommendGoods = result.data.list;
      } else {
        this.$message.error("获取推荐商品失败");
      }
    },

    // 获取商品详情---goodsDetails展示下标题和详细信息以及最下方商品详情图片和轮播商品图
    async getGoodsDetails(id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { code, data } = await this.$api.getGoodsDetailsApi(id);
      loading.close();
      if (code === 200) {
        this.goodsDetails = data.goodsMain;
        document.title = `百川商展-闲物互联-${data.goodsMain.name}`;
        // this.askPingJia(0);
        this.getGoodsList()
        this.comInfo = {
          categoryId: data.goodsMain.categoryId,
          name: data.goodsMain.companyName,
          companyid: data.goodsMain.companyid,
          logo: data.goodsMain.companyLogo,
        };
        this.getMaterList();
        // 商品图片
        const imgArr = data.goodsMain.productImages ? JSON.parse(data.goodsMain.productImages) : [];

        imgArr && imgArr.forEach((item) => {
          this.goodsImgArr.push(this.isMeta ? item.details : item.large);
        });
      } else {
        this.$message.error("获取商品详情失败");
      }
    },
    // 获取商品规格
    async getGoodsMarquee(id) {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const { code, data } = await this.$api.getGoodsMarqueeApi(id);
      loading.close();

      if (code === 200) {
        this.getGoodsMarqueeData = data;//data是所有规格组合的全部可能
        this.allGoodsSpecification();// 需要显示的全部商品规格

        data && data.forEach((item) => {
          this.availableArr.push(item.specificationNames);
        });
        // 默认选择的规格
        this.currentSelect = this.availableArr[0];
      } else {
        this.$message.error("获取商品规格失败");
      }
    },
    // 处理后端返回的规格转化成可渲染的商品规格
    allGoodsSpecification() {
      const obj = {};
      this.getGoodsMarqueeData.length > 0 && this.getGoodsMarqueeData.forEach((item) => {
        const specificationValues = item.specificationValues ? JSON.parse(item.specificationValues).SpecificationsWhole : [];
        specificationValues && specificationValues.forEach((tItem) => {
          if (!obj[tItem.id]) {
            obj[tItem.id] = {
              id: tItem.id,
              name: tItem.name,
              value: [tItem.value],
            };
          } else {
            obj[tItem.id].value.push(tItem.value);
          }
        });
      });
      // 去重
      for (const key in obj) {
        obj[key].value = obj[key].value.filter((item, index) => {
          return obj[key].value.indexOf(item) === index;
        });
        this.allGoodsMarquee.push(obj[key]);
        this.allGoodsMarqueeId.push(obj[key].id);
      }
    },
    // 选择的规格
    selectMarquee(id, value) {
      const index = this.allGoodsMarqueeId.indexOf(id);

      const arr = this.currentSelect.split("/");
      arr.splice(index, 1, value);
      if (!this.availableArr.includes(arr.join("/"))) return;

      this.currentSelect = arr.join("/");
    },
    // 点击小图片
    handleImg(index) {
      this.currentGoodsIndex = index;
    },

    changeImg(value) {
      if (value == 0) {//left
        if (this.currentGoodsIndex == 0) {
          this.currentGoodsIndex = this.goodsImgArr.length - 1
          return
        } else {
          this.currentGoodsIndex--
        }
      } else {//right
        if (this.currentGoodsIndex >= this.goodsImgArr.length - 1) {
          this.currentGoodsIndex = 0
          return
        } else {
          this.currentGoodsIndex++
        }
      }
    },
    // 不可选商品
    isNoGoods(id, value) {
      const index = this.allGoodsMarqueeId.indexOf(id);
      const arr = this.currentSelect.split("/");
      arr.splice(index, 1, value);
      return !this.availableArr.includes(arr.join("/"));
    },

    //加入购物车
    async toAddCar() {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "正在加入购物车...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let arr = this.getGoodsMarqueeData.filter(item => item.specificationNames == this.currentSelect)
      let res = await this.$api.addShopCar({
        productId: arr[0].id,
        amount: arr[0].price,
        isadd: false,
        productNumber: this.selectNumber,
        companyid: this.comInfo.companyid
      })
      loading.close();
      if (res.code == 200) {
        this.$message.success('成功加入购物车')
      } else {
        this.$message.error('加入购物车失败')
      }
    },

    //立即购买
    async toShop() {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "正在生成订单...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let ress = await this.$api.getDiZhi()
      if (ress.code == 200) {
        if (ress.data.length == 0) {
          this.$message.error('您还没有收货地址，请先设置收货地址.')
          loading.close()
          return
        } else {
          let arr = ress.data.filter(item => item.isDefault == 1)
          if (arr && arr.length > 0) {
            this.dizhis = arr
          }else{
            this.dizhis = ress.data
          }
        }
      }
      let css;
      this.getGoodsMarqueeData.length > 0 && this.getGoodsMarqueeData.forEach((item) => {
        if (item.specificationNames == this.currentSelect) {
          css = item.id
        }
      })
      let res = await this.$api.makeOrder({//生成订单
        myCompanyid: this.infoObj.companyid,
        addressId: this.dizhis[0].id,
        remarks: '',
        goodsOnSumList: JSON.stringify([{ goodId: css, goodsNumber: this.selectNumber, }]),
        couponsCode: null,
        memberId: this.infoObj.id,
        type: 1,
      })
      loading.close()

      if (res && res.code == 200) {
        let skipData = {// 支付所需参数
          // orderNo: res.data.orderNumber,//采购订单号（CG开头）
          summaryOrderNo: res.data.summaryOrderNo,//下单号（HB开头）
        };
        this.$router.push({
          name: "GiveMoney",
          query: { info: JSON.stringify(skipData) },
        });
      } else {
        this.$message.error("创建订单失败,请返回重试")
      }
    },

    handleChange(value) {
      this.selectNumber = value
    },

    //收藏和取消收藏
    async toCollect(e) {
      if (this.isLogin) {
        this.setNavIndex("dddd");
        this.$router.push({ name: "Login", });
        return
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const params = {
        id: this.$route.params.goodsId,
        type: 2,
        upvoteType: 2,
        shopping: 1,
      }

      let res;
      let text;
      if (e.target.classList.value == '') {
        res = await this.$api.addCollect(params)
        text = '添加'
        document.querySelector('#star').classList.add("fill")
      } else {
        res = await this.$api.delCollect(params)
        text = '取消'
        document.querySelector('#star').classList.remove("fill")
      }
      loading.close()
      if (res && res.code == 200) {
        this.$message.success(`${text}成功`)
      } else {
        this.$message.error(`操作失败,${res.msg}`)
      }
    },

    //访问收藏列表判断是否被收藏
    async askCollect() {
      if (this.isLogin) {
        return
      } else {
        const res = await this.$api.getCollectListApi({
          type: 2,
          upvoteType: 2,
        })
        if (res.code === 200) {
          let a = res.data.some(item => item.id == this.$route.params.goodsId)
          if (a) {
            setTimeout(() => {
              document.querySelector('#star').classList.add("fill")
            }, 100);
          }
        }
      }
    },

    //评价列表
    async askPingJia(index) {
      this.pingLunIndex = index
      let res = await this.$api.askPingJia(this.goodsDetails.id)
      this.pingLunList = res.data
      this.pingLun1List = res.data.filter(item => item.goodsCommentRank == 3)
      this.pingLun2List = res.data.filter(item => item.goodsCommentRank == 1)
      this.testList = this.pingLunList
    },

    moves(event) {
      if (this.isMobile) {
        return
      }
      let mask = this.$refs.mask;
      let big = this.$refs.big;
      let left = event.offsetX - mask.offsetWidth / 2;
      let top = event.offsetY - mask.offsetHeight / 2;
      //约束范围
      if (left <= 0) left = 0;
      if (left >= mask.offsetWidth) left = mask.offsetWidth;
      if (top <= 0) top = 0;
      if (top >= mask.offsetHeight) top = mask.offsetHeight;
      mask.style.left = left + "px";
      mask.style.top = top + "px";
      big.style.left = -2 * left + "px";
      big.style.top = -2 * top + "px";
    },

    changePingLunIndex(index) {
      this.pingLunIndex = index
      if (index == 3) {
        this.testList = this.pingLun1List
      } else if (index == 1) {
        this.testList = this.pingLun2List
      } else if (index == 0) {
        this.testList = this.pingLunList
      } else {

      }
    },

    //获取商家其他
    async getMaterList() {
      const loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let result = await this.$api.getMaterList({
        companyid: this.comInfo.syscompanyid,
        categoryId: this.comInfo.categoryId,
        goodsMainId: this.goodsDetails.id,
        index: this.tuijianCurrent,
        size: 2,
        type: 17,
      });
      loading.close();
      this.tuijianTotal = result.data?.size - 1;
      this.tuijian = result.data?.list;
    },

    async loadScroll(value) {
      if (value == 0) {
        if (this.tuijianCurrent == 0) {
          this.$message.warning('已经是第一列了');
          return
        }
        this.tuijianCurrent--;
        this.getMaterList()
      } else {
        if (this.noMoreRight) {
          this.$message.warning('没有更多了');
          return
        }
        this.tuijianCurrent++;
        this.getMaterList()
      }
    },

    toddd(item) {
      const openRoute = this.$router.resolve({
        name: "ArticlesDetailGood",
        params: {
          goodsId: item.id,
          isGoodFrom:'false',
        },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.articles-detail {
  .goodtitle {
    font-size: max(14px, 0.8vw);
    background-color: #F7F7F7;
    color: white;
    border-bottom: 1px solid #E43A3D;

    >span {
      background-color: #E43A3D;
      display: inline-block;
      padding: 0.5% 1%;
    }
  }

  .pc_container {
    background-color: white;

    .articles-title {
      padding: 10px 8%;
      background-color: #F2F2F2;
      color: #666666;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      @media screen and (max-width:500px) {
        flex-direction: column;
      }

      .left {
        >span {
          cursor: pointer;
        }
      }

      .right {
        display: flex;
        align-items: center;
        white-space: nowrap;

        .right_good {
          padding: 2px 4px;
          background-color: #FFD1A6;
        }

        .name {
          margin: 0 10px;
          cursor: pointer;
        }

        .btn {
          background-color: #ffffff;
          box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.25);
          border-radius: 50px;
          padding: 6px 25px;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }

    .content_container {
      padding: 0.5% 8%;
      box-sizing: border-box;

      .goods_container {
        box-sizing: border-box;
        display: flex;
        gap: 2%;

        @media screen and (max-width: 700px) {
          flex-direction: column;

          .right {
            .right_item:nth-child(2) {
              margin-right: 2%;
            }

            .right_item {
              display: inline-block;
              width: 48%;
              margin-bottom: 0% !important;
            }
          }
        }

        >div {
          width: 100%;
        }

        .left {
          flex: 2.5;

          .spec-preview {
            position: relative;
            width: 100%;
            aspect-ratio: 1/0.8;
            object-fit: cover;
            border: 1px solid #ccc;

            img {
              width: 100%;
              height: 100%;
            }

            .event {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 998;

              &:hover {
                cursor: move;
              }
            }

            .mask {
              width: 50%;
              height: 50%;
              background-color: rgba(171, 250, 171, 0.3);
              position: absolute;
              left: 0;
              top: 0;
              display: none;
            }

            .big {
              width: 100%;
              height: 100%;
              position: absolute;
              top: -1px;
              left: 100%;
              border: 1px solid #aaa;
              overflow: hidden;
              z-index: 998;
              display: none;
              background: white;

              >img {
                width: 200%;
                max-width: 200%;
                height: 200%;
                position: absolute;
                left: 0;
                top: 0;
              }
            }

            .event:hover~.mask,
            .event:hover~.big {
              display: block;
            }
          }

          >div:nth-child(2) {
            width: 100%;
            display: flex;
            justify-content: center;
            >div:nth-child(1) ,>div:last-child{
              height: max(40px, 4vw);
              margin-bottom: 1%;
              padding: 5px;
              box-sizing: border-box;
              border: 2px solid transparent;
              max-width: 4vw;
              min-width: 40px;
              color: #DFDFDF;
              cursor: pointer;
              text-align: center;
              font-weight: bold;
              font-size: max(30px, 3vw);
              position: relative;
              >i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
            }

            >div:nth-child(2) {
              flex: 1;
              display: flex;
              justify-content: center;

              >img {
                height: max(40px, 4vw);
                margin-bottom: 1%;
                padding: 5px;
                box-sizing: border-box;
                border: 2px solid transparent;
                flex: 1;
                max-width: 4vw;
                min-width: 40px;
                object-fit: cover;

                &.active {
                  border: 2px solid #439c4c;
                }
              }
            }
          }
        }

        .middle {
          flex: 3;

          .middle_top {
            .middle_title {
              border-bottom: 2px solid #E3E3E3;
              padding: 2% 0%;
              font-size: 18px;
              font-weight: bold;
            }

            .middle_content {
              font-size: 14px;
              color: red;
              padding: 2% 0%;
            }

            .middle_price {
              margin-top: 2%;

              >div:nth-child(1) {
                background-color: #439c4c;
                text-align: center;
                padding: 2% 0%;
                color: white;
              }

              >div:nth-child(2) {
                background-color: #F7F7F7;
                padding: 2% 2%;
                color: #d7d7d7;

                >span:nth-child(1) {
                  display: inline-block;
                  margin-bottom: 1%;

                  >span:nth-child(1) {
                    .bigred {
                      color: red;
                      font-size: max(15px, 1.2vw);
                      font-weight: 500;
                      position: relative;

                      &::after {
                        content: '';
                        position: absolute;
                        width: 150%;
                        height: 1px;
                        background-color: red;
                        top: 50%;
                        left: 50%;
                        transform: translate(-60%, -50%);
                      }
                    }
                  }
                }

                >div:nth-child(2) {
                  display: flex;
                  justify-content: space-between;
                  white-space: nowrap;

                  >span:nth-child(2) {
                    display: flex;
                    align-items: flex-end;
                    font-size: 14px;
                  }

                  .bigred {
                    color: red;
                    font-size: max(15px, 1.2vw);
                    font-weight: 500;
                    margin-right: 4%;
                  }
                }
              }
            }

            .specification_list {
              .specification_item {
                border-bottom: 1px solid #888888;

                .title_container {
                  width: 100%;
                  // height: 50px;
                  font-size: 14px;
                  padding: 1% 0%;
                  box-sizing: border-box;
                  border-bottom: 1px solid #888888;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }

                .options_container {
                  width: 100%;
                  box-sizing: border-box;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 1% 0%;

                  .option {
                    padding: 1% 2%;
                    color: #a2afa3;
                    font-size: 12px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 2%;
                    cursor: pointer;
                    border: 1px solid #439c4c;
                    box-sizing: border-box;

                    &.active {
                      color: #fff;
                      background-color: #439c4c;
                    }

                    &.noGoods {
                      color: #fff;
                      background-color: #d7d7d7;
                      border: 1px solid transparent;
                    }
                  }
                }
              }
            }

            .select_number {
              padding: 5% 0%;
              font-size: 14px;
              display: flex;
              align-items: center;
              // justify-content: space-between;

              .numbers {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                >span {
                  display: inline-block;
                  padding: 0% 2%;
                }

                >img {
                  height: 1.3vw;
                  cursor: pointer;

                  &:active {
                    transform: scale(0.95);
                  }
                }
              }

              .btn {
                background: #439c4c;
                height: 40px;
                line-height: 40px;
                color: #fff;
                text-align: center;
                padding: 0% 3%;
                cursor: pointer;
                margin-left: 2%;
              }

              .btn:nth-child(3) {
                background-color: #cccccc;
              }

              :deep(.el-input-number) {
                height: 100% !important;
                width: 59px;

                .el-input-number__increase,
                .el-input-number__decrease {
                  width: 15px !important;
                }

                .el-icon-arrow-up {
                  &::before {
                    content: "\e6d9";
                  }
                }

                .el-icon-arrow-down {
                  &::before {
                    content: "\e6d8";
                  }
                }
              }

              :deep(.el-input__inner) {
                border-radius: 0px !important;
                padding-right: 15px;
                padding-left: 0px;
              }
            }

            .xins {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 5%;
              padding: 1%;
              border: 1px solid #E5E5E5;
              border-radius: 10px;

              >div:nth-child(1) {
                cursor: pointer;
                white-space: nowrap;

                >div {
                  margin-top: 10%;
                }
              }

              #star {
                vertical-align: middle;
                display: inline-block;
                width: 30px;
                height: 30px;
                background-image: url(../assets/icon/collect.png);
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center;
                margin: 0% 1%;
              }

              .fill {
                position: relative;
                animation: banuce 0.2s ease 0s 2 alternate;
                background-image: url(../assets/icon/collect2.png) !important;

                &::before {
                  opacity: 0;
                  content: '';
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  width: 10%;
                  height: 10%;
                  transform: translate(-50%, -50%);
                  border-radius: 50%;
                  background: transparent;
                  box-shadow: 15px -15px 0 #fed001, 15px 15px 0 #fed001,
                    -15px -15px 0 #fed001, -15px 15px 0 #fed001;
                  animation: show 0.2s steps(1, end) 0s 1;
                }

                &::after {
                  opacity: 0;
                  content: '';
                  position: absolute;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  animation: circle 0.3s ease 0.02s 1 alternate;
                  border-radius: 50%;
                  background: rgba(254, 208, 1, 0.1);
                }

                @keyframes banuce {
                  0% {
                    transform: scale(0.8);
                  }

                  100% {
                    transform: scale(1.35);
                  }
                }

                @keyframes circle {
                  0% {
                    transform: scale(0.2);
                    opacity: 0.8;
                  }

                  100% {
                    transform: scale(1.5);
                    opacity: 1;
                  }
                }

                @keyframes show {
                  0% {
                    opacity: 1;
                  }
                }
              }

              >div:nth-child(2) {
                line-height: 200%;
                color: #929292;
              }
            }
          }
        }

        .right {
          flex: 1;
          text-align: center;
          color: red;
          font-weight: 500;
          font-size: max(14px, 1vw);
          padding: 0% 5%;
          box-sizing: border-box;

          @media screen and (max-width: 700px) {
            margin-top: 5%;
          }

          >div:nth-child(1) {
            position: relative;
            text-align: center;
            padding: 10% 0%;
            color: #5D5D5D;
            font-size: max(12px, 0.8vw);

            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0%;
              transform: translate(-50%, -50%);
              width: 40%;
              height: 1px;
              background-color: #e6e6e6;
            }

            &::after {
              content: '';
              position: absolute;
              top: 50%;
              right: 0%;
              transform: translate(50%, -50%);
              width: 40%;
              height: 1px;
              background-color: #e6e6e6;
            }
          }

          .right_item {
            cursor: pointer;
            margin-bottom: 5%;
          }

          img {
            width: 100%;
            aspect-ratio: 1.1/1;
            object-fit: cover;
          }

          .right_icon {
            color: #E1E1E1;
            font-size: max(30px, 2.5vw);
            display: flex;
            justify-content: center;
            gap: 10%;

            >i {
              cursor: pointer;

              &:active {
                transform: scale(0.6);
              }
            }

            >i:nth-child(1) {
              transform: rotate(90deg);
            }

            >i:nth-child(2) {
              transform: rotate(90deg);
            }
          }
        }
      }

      .words {
        margin-top: 10px;

        .words_title {
          @extend .goodtitle;
          letter-spacing: 3px;
          cursor: pointer;

          >span {
            background-color: transparent;
            color: black;
            transition: all .5s;
          }

          .wasyou {
            background-color: #E43A3D;
            color: white;
          }
        }

        .uls {
          height: max(200px, 20vw);
          overflow-x: auto;
          font-size: max(12px, 1vw);
          @include scrollbar();
          position: relative;

          >li {
            margin-bottom: 1vw;

            >div:nth-child(2) {
              display: flex;
              align-items: center;
            }
          }

          &:empty::after {
            content: '暂无评价...';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // font-family: 'title';
            font-size: 1vw;
          }
        }

        .materials_html {
          position: relative;
          margin-top: 5%;

          &:empty::after {
            content: '暂无详情...';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // font-family: 'title';
            font-size: 1vw;
          }
        }
      }

      .recommend_goods {
        margin-top: 3%;
        margin-bottom: 4%;

        .recommend_title {
          @extend .goodtitle;
        }

        .carousel_container {
          width: 100%;
          box-sizing: border-box;
          margin-top: 20px;
          display: flex;
          overflow-y: hidden;
          overflow-x: auto;

          .recommend_item {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            cursor: pointer;
            width: 15%;
            text-align: center;

            img {
              width: 100%;
              aspect-ratio: 1/1;
            }

            >div:nth-child(2)>span {
              margin-top: 1%;
              display: block;
              text-align: center;
              color: red;
              font-weight: 500;
              font-size: max(14px, 1vw);
            }
          }
        }
      }
    }
  }
}
</style>
  